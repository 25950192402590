import DataCycleInit from '../../../vendor/gems/data-cycle-core/app/assets/javascripts/application';
import appSignalInit from 'datacycle-appsignal-adapter';

switch (import.meta.env.MODE) {
  case 'production':
    appSignalInit('deea78e8-8184-4ff3-81e2-0d1795049bf1');
    break;
  case 'staging':
    appSignalInit('8a50e314-388c-4263-b41a-f9dac8e7687e');
    break;
}

DataCycleInit({}, () => {
  if (window.appSignal) DataCycle.notifications.addEventListener('error', ({ detail }) => appSignal.sendError(detail));
});
